import {graphql} from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet'

import Layout from './layout';

export default class CustomerGallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {locked: true};
  }

  validatePassword(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const password = document.querySelector('#password').value;
    if (password === this.props.data.customerGalleriesYaml.password) {
      this.setState({locked: false});
    }
  }

  renderImages() {
    const yaml_data = this.props.data.customerGalleriesYaml;

    const imageURL = function(idx) {
      let i = String(idx + 1);
      while (i.length < 3) {
        i = '0' + i;
      }
      return `${yaml_data.image_base_url}/${i}.jpg`;
    }

    const images = [];
    for (let i = 0; i < yaml_data.image_count; i++) {
      images.push(<img key={i} src={imageURL(i)} alt=''/>);
    }
    return images;
  }

  renderForm() {
    return (
      <form className='customer-gallery-password-form'>
        <label>Geslo:
          <input id='password' type='text'/>
        </label>
        <button onClick={(evt) => this.validatePassword(evt)}>Vstopi</button>
      </form>
    );
  }

  render() {
    const lang = this.props.pageContext.language;
    const yaml_data = this.props.data.customerGalleriesYaml;

    return (
      <Layout language={lang}>
        <Helmet>
          <title>Andraž Gregorič - {yaml_data.title}</title>
        </Helmet>
        <div className='main-content' ref={el => this.instance = el}>
          <h1>{yaml_data.title}</h1>
          {this.state.locked ? this.renderForm() : null}
        </div>
        <div className='standard-gallery'>
          {this.state.locked ? null : this.renderImages()}
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    customerGalleriesYaml(id: {eq: $id}) {
      slug
      title
      password
      image_base_url
      image_count
    }
  }
`;
